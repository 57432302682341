import React from 'react'
const IconInfo = (props) => {
    //console.log('props', props);
    return (
        <>
            <span className={`icon-info style-${props.style} position-${props.position}`}>
                <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="white" d="M7.00016 13.6666C3.31816 13.6666 0.333496 10.682 0.333496 6.99998C0.333496 3.31798 3.31816 0.333313 7.00016 0.333313C10.6822 0.333313 13.6668 3.31798 13.6668 6.99998C13.6668 10.682 10.6822 13.6666 7.00016 13.6666ZM7.00016 12.3333C8.41465 12.3333 9.7712 11.7714 10.7714 10.7712C11.7716 9.77102 12.3335 8.41447 12.3335 6.99998C12.3335 5.58549 11.7716 4.22894 10.7714 3.22874C9.7712 2.22855 8.41465 1.66665 7.00016 1.66665C5.58567 1.66665 4.22912 2.22855 3.22893 3.22874C2.22873 4.22894 1.66683 5.58549 1.66683 6.99998C1.66683 8.41447 2.22873 9.77102 3.22893 10.7712C4.22912 11.7714 5.58567 12.3333 7.00016 12.3333ZM6.3335 3.66665H7.66683V4.99998H6.3335V3.66665ZM6.3335 6.33331H7.66683V10.3333H6.3335V6.33331Z" />
                </svg>
                <span className="visBlock">{props.text}</span>
            </span>
        </>
    )
};
export default IconInfo;